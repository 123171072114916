<template>
  <div>
    <div class="row w-100">
      <div id="headerHome" class="col-sm-12 col-md-12 color-bg-4">
        <HeaderMenu />
      </div>
    </div>
    <div class="header-pol-priv">TERMINOS Y CONDICIONES</div>
    <div class="politica-cont">
      <!--<div class="titulo-pol">AVISO DE <span>PRIVACIDAD</span></div>--->
      <p class="texto-pol">
        A los Usuarios, les informamos que los siguientes Términos y Condiciones les son aplicables por el simple uso o acceso a cualquiera de las páginas, aplicaciones web y móviles, softwares y, aplicaciones en general, que integran el Portal de www.agriga.com.mx (en adelante y, conjunta e indistintamente, el "Portal"), por lo que entenderemos que los acepta y acuerda en obligarse a su cumplimiento. <b>En el caso de que no esté de acuerdo con los Términos y Condiciones deberá abstenerse de acceder o utilizar el Portal.</b>
      </p>

      <p class="texto-pol">
        Agriga de México (en adelante y, conjunta e indistintamente, la “Empresa”) se reserva el derecho de modificar discrecionalmente el contenido del Portal en cualquier momento, sin necesidad de previo aviso.
      </p>

      <p class="texto-pol">
        El Usuario, entendido como aquella persona que realiza el uso o accede al Portal, mediante equipo de cómputo y/o cualquier equipo de comunicación o dispositivo (en adelante el “Usuario”), conviene en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Portal, en las bases de datos y/o información que se contenga en el mismo.
      </p>

      <p class="texto-pol t-pol">1. USO Y RESTRICCIONES.</p>

      <p class="texto-pol">
        El acceso o utilización del Portal expresan la adhesión plena y sin reservas del Usuario a los presentes Términos y Condiciones. A través del Portal, el Usuario comprará y utilizará diversos servicios y productos puestos a disposición por la Empresa. La Empresa tendrá el derecho a negar, restringir o condicionar al Usuario el acceso al Portal, total o parcialmente, a su entera discreción, así como a modificar los servicios y productos del Portal en cualquier momento y sin necesidad de previo aviso.
      </p>

      <p class="texto-pol">
        El Usuario reconoce que no todos los servicios y productos están disponibles en todas las áreas geográficas y que algunos servicios y productos pueden ser utilizados solamente con posterioridad a su contratación, compra o registro previo por el Usuario y/o mediante el pago de estos. La Empresa no garantiza la disponibilidad y continuidad de la operación del Portal y de los servicios y productos, ni la utilidad del Portal o los servicios y productos en relación con cualquier actividad específica, independientemente del medio de acceso que utilice el Usuario. La Empresa no será responsable por daño o pérdida alguna de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o continuidad de operación del Portal y/o de los servicios y productos.
      </p>

      <p class="texto-pol">
        El aprovechamiento de los servicios y productos en el Portal, es exclusiva responsabilidad del Usuario, quien en todo caso deberá servirse de ellos acorde a las funcionalidades permitidas en el propio Portal y a los usos autorizados en los presentes Términos y Condiciones, por lo que el Usuario se obliga a utilizarlos de tal modo que no contravengan las buenas costumbres, los derechos de terceros, las normas de uso y convivencia en Internet, las leyes de los Estados Unidos Mexicanos y, la legislación vigente en el país en que el Usuario se encuentre al acceder al Portal y usar los servicios y productos. El Portal es para el uso individual del Usuario por lo que no podrá comercializar de manera alguna los servicios y productos.
      </p>

      <p class="texto-pol t-pol">2. RESTRICCIONES.</p>

      <p class="texto-pol">
        El Usuario no tiene el derecho de colocar hiperligas dentro del Portal, a utilizar las ligas del Portal, ni el derecho de colocar o utilizar los servicios y productos en sitios o páginas propias o de terceros sin autorización previa y por escrito de la Empresa. El Usuario no podrá impedir servicios y productos a cualquier otro Usuario el uso del Portal ni de los servicios y productos.
      </p>

      <p class="texto-pol t-pol">3. PROPIEDAD INTELECTUAL.</p>

      <p class="texto-pol">
        Los derechos de propiedad intelectual, propiedad industrial respecto de los servicios y productos, los signos distintivos y dominios de las Páginas o del Portal, así como los derechos de uso y explotación de estos, incluyendo de manera enunciativa más no limitativa, su divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de la Empresa. El Usuario no adquiere ningún derecho de propiedad intelectual y/o industrial por el simple uso o acceso de los servicios y productos del Portal y, en ningún momento, dicho uso será considerado como una autorización o licencia para utilizar los servicios y productos con fines distintos a los que se contemplan en los presentes Términos y Condiciones.
      </p>

      <p class="texto-pol t-pol">4. PROPIEDAD INTELECTUAL DE TERCEROS.</p>

      <p class="texto-pol">
        El Usuario acuerda que las disposiciones que se establecen en el artículo 3 anterior respecto de la titularidad de los derechos de la Empresa, también son aplicables a los derechos de terceros respecto de los servicios y productos de las Páginas, dominios o información presentada o vinculada al Portal.
      </p>

      <p class="texto-pol t-pol">
        5. CALIDAD DE LOS SERVICIOS Y PRODUCTOS Y SUS GARANTÍAS. 
      </p>
      <p class="texto-pol">
        Ni la Empresa, ni sus proveedores o socios comerciales, serán responsables de cualquier daño o perjuicio que sufra el Usuario a consecuencia de inexactitudes, consultas realizadas, asesorías, errores tipográficos y cambios o mejoras que se realicen periódicamente a los servicios y productos. Las recomendaciones y consejos obtenidos a través del Portal son de naturaleza general, por lo que no deben tomarse en cuenta en la adopción de decisiones personales ni profesionales. Para ello, se debe consultar a un profesional apropiado que pueda asesorar al Usuario de acuerdo con sus necesidades específicas.
      </p>
      <p class="texto-pol">
        La Empresa ofrece los servicios y productos con un nivel de competencia y diligencia razonable desde un punto de vista comercial, sin embargo, no ofrece ningún tipo de garantía en relación con estos. El Portal es proporcionado por la Empresa "tal como está" y "según disponibilidad". La Empresa no manifiesta ninguna representación o garantía de ningún tipo, expresa o implícita, en relación con la operación del Portal, información, contenido, materiales o productos incluidos. El Usuario acepta expresamente que el uso del Portal es bajo su propio riesgo. La Empresa se reserva el derecho a remover o eliminar cualquier información del Portal, en cualquier momento, a su entera discreción. Ni la Empresa, ni sus proveedores o distribuidores ofrecen garantías específicas sobre los servicios y productos; la Empresa excluye todas las garantías en la medida que las leyes vigentes lo permitan.
      </p>
      <p class="texto-pol">
        De conformidad con los Términos y Condiciones, la Empresa no asume ni asumirá ningún tipo de y responsabilidad frente a ninguna persona, derivada o que pudiera derivarse por los servicios y productos, navegación en el Portal, consultas, aclaraciones y/o cualquier otra clase de respuesta otorgada por parte de la Empresa por cualquier medio de comunicación.
      </p>
      <p class="texto-pol">
        El Usuario se obliga a dejar en paz y a salvo a la Empresa, a sus accionistas, subsidiarias, afiliadas, funcionarios, directores, empleados, asesores, apoderados, representantes y/o cualquier persona relacionada con esta, de cualquier responsabilidad que pudiera imputarse en virtud y/o en relación con el Portal, la prestación de los servicios y productos o cualquier otro derivado de los presentes Términos y Condiciones.
      </p>
      <p class="texto-pol">
        El Usuario entiende y acepta que la Empresa se encontrará limitada por responsabilidad de cualquier tipo, en todos los casos, al monto pagado como contraprestación por los Servicios y Contenidos.
      </p>


      <p class="texto-pol t-pol">
        6. SUSCRIPCIONES. 
      </p>
      <p class="texto-pol">
        Es posible que para el uso y/o compra de algunos o todos los servicios y productos, sea necesario el registro del Usuario y que este cuente con un método de pago automático y válido, asociado a dicho registro, método que podrá ser modificado o cancelado, a discreción del Usuario. En su caso, el Usuario se sujetará a los Términos y Condiciones del portal y la plataforma de pagos.
      </p>


      <p class="texto-pol t-pol">
        7. PRODUCTOS, OFERTAS Y STOCK. 
      </p>
      <p class="texto-pol">
        El precio neto de los productos en el portal se muestra en pesos mexicanos, con los impuestos correspondientes incluidos en el caso en el que aplique. 
      </p>
      <p class="texto-pol">
        El precio del producto es el vigente al momento de la compra, una vez se acepta el carrito y se procede al pago. Los precios expuestos pueden revisarse y modificarse en cualquier momento por Agriga de México sin que ello genere derechos a quienes ya han realizado compras posteriormente.
      </p>
      <p class="texto-pol">
        Las ofertas tienen una duración temporal concreta, por eso las ofertas incluidas en la tienda online del Portal serán válidas durante el período que esté fijado o, en su defecto, durante todo el tiempo que permanezcan accesibles al Usuario para su compra.
      </p>
      <p class="texto-pol">
        Los productos que están en el Portal son productos en stock y por lo tanto están disponibles en los almacenes de la empresa y listos para el envío en la fecha que señale el Usuario en su orden de compra.
      </p>
      <p class="texto-pol">
        Si debido a una carencia de stock en el almacén por error informático o logístico no se pudiera atender el pedido total o parcialmente, la Empresa se pondrá en contacto con el Usuario a través de la dirección de correo electrónico o teléfono, para la confirmación del mismo y su posterior envío en un plazo diferente al general establecido en esta política, o bien para proceder a la anulación del pedido y devolución del importe. La devolución se realizará a través del medio de pago utilizado por el usuario.
      </p>
      <p class="texto-pol">
        De forma esporádica, en función de la temporada y del stock disponible, alguno de los productos pueden variar.
      </p>
      <p class="texto-pol">
        Se recomienda al Usuario que, además de ver la foto, compruebe que la descripción del producto que quiere comprar coincide con lo que desea.
      </p>


      <p class="texto-pol t-pol">
        8. SISTEMA DE COMPRA. 
      </p>
      <p class="texto-pol">
        Para realizar una compra es necesario entrar al Portal www.agriga.com.mx. Una vez elegido el producto, el Usuario podrá ir al carrito y comprobar su pedido con los gastos asociados que en su caso se generen. Podrá elegir finalizar la compra o seguir comprando. Si elige finalizar deberá rellenar los datos necesarios para cumplir con la finalidad de entrega del producto.
      </p>


      <p class="texto-pol t-pol">
        9. FORMA DE PAGO Y FACTURACIÓN.
      </p>
      <p class="texto-pol">
        El Usuario puede realizar el pago de su compra con tarjeta de crédito o débito mediante Open Pay. De ninguna manera la empresa podrá acceder a los datos económicos o bancarios de los usuarios ya que los pagos se hacen directamente en la plataforma antes mencionada, una pasarela de pago segura. 
      </p>
      <p class="texto-pol">
        Para finalizar el pedido el Usuario deberá aceptar las condiciones de venta y el aviso legal, las condiciones de uso y la política de protección de datos, a cuyos contenidos puede el Usuario acceder a través de los links. No será posible realizar una compra sin esta aceptación. Si tiene dudas sobre las mismas podrá remitir un email a ventas@agriga.com.mx. 
      </p>
      <p class="texto-pol">
        Si el Usuario desea una factura de su compra puede acudir a la sección correspondiente dentro del Portal, donde deberá introducir sus datos personales y hacer la solicitud de la misma. En caso de dudas, se puede contactar con la Empresa a través de email ventas@agriga.com.mx.
      </p>
      <p class="texto-pol">
        Al confirmar el pedido, el Usuario recibirá en el plazo máximo de 24 horas, en el correo electrónico que haya facilitado en los datos de facturación, una confirmación del pedido realizado.
      </p>


      <p class="texto-pol t-pol">
        10. ENVÍOS Y DEVOLUCIONES. 
      </p>
      <p class="texto-pol">
        La entrega de los pedidos se realizará a través de una agencia de transporte ajena a la empresa. La misma será elegida por el usuario al momento de hacer su compra. 
      </p>
      <p class="texto-pol">
        Los pedidos se entregarán en el domicilio indicado por el Usuario, por lo que la Empresa no asume ninguna responsabilidad cuando la entrega no pueda efectuarse como consecuencia de que los datos facilitados sean inexactos o incompletos o cuando la entrega no pueda efectuarse por ausencia del destinatario. Sin perjuicio de lo anterior, la Empresa adoptará las medidas exigibles a un diligente comerciante para que la entrega pueda efectuarse lo antes posible, tanto para satisfacción del remitente como del destinatario.
      </p>
      <p class="texto-pol">
        Los plazos de entrega indicados son aproximados y pueden sufrir alteraciones por causas o circunstancias especiales (fechas señaladas como las navideñas, huelgas de transporte, acontecimientos extraordinarios…), que en ningún caso son responsabilidad de la empresa.
      </p>
      <p class="texto-pol">
        La Empresa no puede garantizar la entrega de los pedidos en hospitales, organismos públicos u otros inmuebles en los que existan restricciones para el acceso al público en general, quedando cumplido el encargo y liberado de responsabilidad, cuando el pedido se haya puesto a disposición del destinatario en la recepción o lugar que haga las veces de control de acceso.
      </p>
      <p class="texto-pol">
        Si el Usuario todavía no ha recibido el envío en el tiempo previamente pactado, bastará con que remita la declaración al correo ventas@agriga.com.mx procediendo la empresa a la devolución del importe abonado por el usuario (incluidos los gastos de envío si se trata del envío completo). 
      </p>
      <p class="texto-pol">
        En caso de que el Usuario reciba el pedido dañado o en malas condiciones o haya habido un error en el envío, tiene derecho a la devolución debiendo notificarlo a la empresa en las primeras 24 horas tras su recepción mandando un correo electrónico a ventas@agriga.com.mx en el que deberá poner en el asunto “devolución de pedido dañado”, “devolución de pedido defectuoso” o “error en el envío”. 
      </p>
      <p class="texto-pol">
        La Empresa se pondrá en contacto con el usuario para indicarle los pasos a seguir para hacer el cambio. Los gastos de envío de la devolución correrán por cuenta del usuario. Si se comprobara que efectivamente estaba dañado el pedido, era defectuoso o efectivamente hubo un error en el envío, se procederá a la sustitución del producto siempre que este se haya realizado de acuerdo con las instrucciones de devolución dadas por la empresa.
      </p>
      <p class="texto-pol">
        La Empresa dispone de un control exhaustivo de envíos, por lo que antes de cualquier devolución se procederá a la verificación de los stocks, pesos, y otras medidas de seguridad que se tienen instaladas para asegurar que los envíos sean correctos. Los gastos de envío de la devolución correrán por cuenta de la Empresa.
      </p>


      <p class="texto-pol t-pol">
        11. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS.  
      </p>
      <p class="texto-pol">
        El hecho de que se ofrezca información en el Portal o en otros sitios ligados o vinculados, no implica la recomendación, garantía, patrocinio o aprobación por parte de la Empresa respecto de dicha información, bienes y/o servicios. La disponibilidad de bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados, no es responsabilidad de la Empresa. En virtud de lo anterior, la Empresa no será responsable ante cualquier autoridad de cualquier naturaleza, por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados a través del Portal.
      </p>
      <p class="texto-pol">
        Respecto de los servicios y productos que prestan terceros dentro o mediante enlaces del Portal (tales como ligas, banners y botones), la Empresa se limita exclusivamente, para conveniencia del Usuario, a: (i) informar al Usuario sobre los mismos y, (ii) a proporcionar un medio para poner en contacto al Usuario con proveedores o vendedores. Los productos y/o servicios que se comercializan dentro del Portal y/o en los sitios de terceros enlazados son suministrados por comerciantes independientes y no se entenderá en ningún caso que son responsabilidad de la Empresa. No existe ningún tipo de relación laboral, asociación o sociedad, entre la Empresa y dichos terceros. Toda asesoría, consejo, declaración, información y contenido de las páginas de terceros enlazadas o dentro del Portal representan las opiniones y juicios de dicho tercero, consecuentemente, la Empresa no será responsable de ningún daño o perjuicio que sufra el Usuario a consecuencia de estos.
      </p>


      <p class="texto-pol t-pol">
        12. CONFIDENCIALIDAD.  
      </p>
      <p class="texto-pol">
        La Empresa se obliga a mantener confidencial la información que reciba del Usuario que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos; la Empresa no asume ninguna obligación de mantener confidencial cualquier otra información que el Usuario le proporcione.
      </p>


      <p class="texto-pol t-pol">
        13. USO DE LA INFORMACIÓN NO CONFIDENCIAL.  
      </p>
      <p class="texto-pol">
        Mediante el uso del Portal, el Usuario autoriza a la Empresa, de manera enunciativa más no limitativa, a utilizar, publicar, reproducir, divulgar, comunicar públicamente y transmitir la información no confidencial, en términos de lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, en la Ley Federal de los Derechos de Autor, en la Ley Federal de Protección al Consumidor y en cualquiera otra aplicable en la legislación mexicana.
      </p>


      <p class="texto-pol t-pol">
        14. COOKIES.  
      </p>
      <p class="texto-pol">
        El Usuario que tenga acceso al Portal, conviene en recibir archivos que les transmitan los servidores de la Empresa. Una "Cookie" es un archivo de datos que se almacena en el disco duro de la computadora del Usuario cuando éste acceda al Portal. Dichos archivos pueden contener información tal como la identificación proporcionada por el Usuario o información para rastrear las páginas que el Usuario ha visitado. Una Cookie no puede leer los datos o información del disco duro del Usuario ni leer las Cookies creadas por otros sitios o páginas. Generalmente, las Cookies son aceptadas automáticamente, el Usuario puede cambiar la configuración de su navegador en cualquier momento. En caso de que el Usuario decida rechazar las Cookies, es posible que ciertas secciones del Portal no tengan su funcionamiento óptimo o incluso no funcionen en absoluto.
      </p>


      <p class="texto-pol t-pol">
        15. AVISO DE PRIVACIDAD DE DATOS PERSONALES.  
      </p>
      <p class="texto-pol">
        Toda la información que la Empresa recabe del Usuario es tratada con absoluta confidencialidad conforme las disposiciones legales aplicables en la legislación mexicana. Para conocer más sobre la protección de sus datos personales por favor consulte nuestro Aviso de Privacidad.
      </p>


      <p class="texto-pol t-pol">
        16. CLAVES DE ACCESO.
      </p>
      <p class="texto-pol">
        En todo momento, el Usuario es el responsable único y final de mantener en secreto las claves de acceso que pudiera tener y con las cuales tuviera acceso a ciertos Servicios y Contenidos del Portal.
      </p>


      <p class="texto-pol t-pol">
        16.1 CUENTAS.  
      </p>
      <p class="texto-pol">
        El Usuario, al crear una cuenta en el Portal, declara, bajo protesta de decir verdad, que tiene como mínimo 18 años de edad o la mayoría de edad legal en su jurisdicción; el Usuario reconoce que es su responsabilidad cualquier actividad que se desarrolle con dicha cuenta o a través de ella y reconoce que tiene conocimiento y acepta las condiciones establecidas en los presentes Términos y Condiciones y en el Aviso de Privacidad.
      </p>


      <p class="texto-pol t-pol">
        17. MODIFICACIONES. 
      </p>
      <p class="texto-pol">
        La Empresa tendrá el derecho de modificar, en cualquier momento, los Términos y Condiciones, sin previo aviso y/o consentimiento del Usuario. En consecuencia, el Usuario debe leer atentamente los Términos y Condiciones cada vez que pretenda utilizar el Portal. Ciertos servicios y productos ofrecidos a los Usuarios en y/o a través del Portal están sujetos a condiciones particulares propias que sustituyen, completan y/o modifican los presentes Términos y Condiciones. Consiguientemente, el Usuario también debe leer atentamente las correspondientes condiciones particulares antes de acceder a cualquiera de los servicios y productos.
      </p>


      <p class="texto-pol t-pol">
        18. LEYES APLICABLES Y JURISDICCIÓN.
      </p>
      <p class="texto-pol">
        Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones, el Usuario está de acuerdo en que serán aplicables las leyes Federales de los Estados Unidos Mexicanos y competentes los tribunales de Hermosillo Sonora, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles debido a sus domicilios presentes o futuros o por cualquier otra causa.
      </p>


      <p class="texto-pol t-pol">
        19. CONTACTO. 
      </p>
      <p class="texto-pol">
        El Usuario puede contactar, en todo momento, al personal de la Empresa para cualquier aclaración, comentario, duda y/o sugerencia relacionada con los servicios y productos, con el Portal y/o con los presentes Términos y Condiciones en ventas@agriga.com.mx.
      </p>
    </div>
    <div class="w-100">
      <div id="footer" class="color-bg-4">
        <Footer />
      </div>
    </div>
  </div>
</template>


<script>
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import Footer from "../components/fixedItems/Footer.vue";
export default {
  name: "politicas",
  components: {
    HeaderMenu,
    Footer,
  },
};
</script>


<style scoped>
.header-pol-priv {
  width: 100%;
  height: 18.385vw;
  background-image: url("../assets/aviso-priv.png");
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 2.344vw;
  line-height: 99.5%;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.politica-cont {
  padding: 6.302vw 15.521vw;
}
p.texto-pol {
  font-size: 1.042vw;
  line-height: 194.5%;
  text-align: justify;
  color: #a0a0a0;
  margin: 0 0 2.2552vw;
}
p.texto-pol.t-pol{
  margin: 2.2552vw 0 0;
  text-transform: uppercase;
  font-weight: 600;
}
.titulo-pol {
  font-size: 2.344vw;
  line-height: 2.708vw;
  color: #000000;
  font-family: "Lato";
  margin-bottom: 3vw;
}
.titulo-pol span {
  color: #000000;
  font-family: "Lato";
}
p.texto-pol.lista-pol {
  margin: 0;
}
p.texto-pol.lista-pol.last {
  margin: 0 0 2.344vw; 
}

@media (max-width: 768px) {
  .header-pol-priv {
    width: auto;
    height: 48.832vw;
    background-image: url("../assets/aviso-priv-2.png");
    font-size: 8.178vw;
    padding: 0 23.131vw;
  }
  .politica-cont {
    padding: 23.598vw 16.121vw 26.869vw;
  }
  .titulo-pol {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 12.077vw;
  }
  p.texto-pol {
    font-size: 2.899vw;
    line-height: 179.5%;
    margin: 4.2552vw 0;
  }
}
</style>
